.colophon {
    h2 {
        margin: 2rem auto 1rem;
    }
    
    .credit-line {
        margin-bottom: 1rem;
    }
    
    .item
    , .credit {
        display: inline-block;
        width: 49%;
    }
    
    .item {
        text-align: right;
        padding-right: 0.25rem;
        font-style: italic;
    }
    
     .credit {
        text-align: left;
        padding-left: 0.25rem;
    }
   
}